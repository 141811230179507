import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import {
	getShortcutString,
	LIVE_PAGE_MODE_SWITCH_TO_EDIT,
	LIVE_PAGE_MODE_SWITCH_TO_VIEW,
} from '@confluence/shortcuts';
import { getLogger } from '@confluence/logger';
import { fg } from '@confluence/feature-gating';
import { confluenceLocalStorageInstance as localStorage, keys } from '@confluence/storage-manager';
import type { FlagsStateContainer } from '@confluence/flags';
import { useLivePageMode } from '@confluence/live-pages-utils/entry-points/useLivePagesStore';
import { mergeLiveDocI18n } from '@confluence/live-pages-utils/entry-points/mergeLiveDocI18n';

import { LivePagesModeToggle } from './LivePagesModeToggle';
import { LivePagesModeChangerDropdown } from './LivePagesModeChangerDropdown';

const i18nBase = defineMessages({
	livePagesEditModeFlagTitle: {
		id: 'live-pages-features.live-pages.edit-mode-flag.title',
		defaultMessage: `You’re editing this page`,
		description:
			'Title for the flag that displays when a user changes a live page to be in edit mode that will allow them to make any modifications to the content',
	},
	livePagesEditModeFlagDescription: {
		id: 'live-pages-features.live-pages.edit-mode-flag.description',
		defaultMessage: 'Tip: To switch to viewing only, use the keyboard shortcut {keyCombination}.',
		description:
			'This message appears when a user switches a live page to edit mode, indicating that editing is enabled. It provides a tip on how to switch back to view-only mode by using a keyboard shortcut',
	},
	livePagesViewModeFlagTitle: {
		id: 'live-pages-features.live-pages.view-mode-flag.title',
		defaultMessage: `You’re viewing this page`,
		description:
			'Title for the flag that displays when a user changes a live page to be in view only mode that will not allow them to make any modifications to the content',
	},
	livePagesViewModeFlagDescription: {
		id: 'live-pages-features.live-pages.view-mode-flag.description',
		defaultMessage: 'Tip: To start editing, select {keyCombination} on your keyboard.',
		description:
			'This message appears when a user switches a live page to view-only mode, indicating that editing is disabled. It provides a tip on how to re-enable editing by pressing a keyboard shortcut.',
	},
});
const i18nLiveDocs = defineMessages({
	livePagesEditModeFlagTitle: {
		id: 'live-pages-features.live-pages.edit-mode-flag.title.livedocs',
		defaultMessage: `You’re editing this live doc`,
		description:
			'Title for the flag that displays when a user changes a live doc to be in edit mode that will allow them to make any modifications to the content',
	},
	livePagesViewModeFlagTitle: {
		id: 'live-pages-features.live-pages.view-mode-flag.title.livedocs',
		defaultMessage: `You’re viewing this live doc`,
		description:
			'Title for the flag that displays when a user changes a live doc to be in view only mode that will not allow them to make any modifications to the content',
	},
});
export const i18n = mergeLiveDocI18n(i18nBase, i18nLiveDocs);

export type LivePageMode = 'edit' | 'view';
export interface LivePagesModeChangerProps {
	contentId: string;
	isDisabled?: boolean;
	flags: FlagsStateContainer;
	analyticsSource?: string;
	elemAfter?: ({ shortcutKeys, id }: { shortcutKeys: string[]; id: string }) => React.ReactNode;
}

export const LivePagesModeChanger = ({
	contentId,
	isDisabled,
	flags,
	analyticsSource,
	elemAfter,
}: LivePagesModeChangerProps) => {
	const intl = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const logger = getLogger('editor-mainbuttons-livepage-modechanger');

	const [{ mode: livePageMode }, { setMode: setLivePageMode }] = useLivePageMode();

	const hasLivePageModeFlagBeenShown = (mode: LivePageMode): boolean => {
		try {
			const flagsShown = JSON.parse(
				localStorage.getItem(keys.LIVE_PAGES_MODE_SWITCHING_FLAG_SHOWN) || '{}',
			);
			return flagsShown[mode] === true;
		} catch (error) {
			logger.error`Error checking if live page mode flag has been shown for mode: "${mode}". Reason: ${error.message}`;

			return false;
		}
	};

	const setLivePageModeFlagAsShown = (mode: LivePageMode): void => {
		try {
			const flagsShown = JSON.parse(
				localStorage.getItem(keys.LIVE_PAGES_MODE_SWITCHING_FLAG_SHOWN) || '{}',
			);
			flagsShown[mode] = true;
			localStorage.setItem(keys.LIVE_PAGES_MODE_SWITCHING_FLAG_SHOWN, JSON.stringify(flagsShown));
		} catch (error) {
			logger.error`Error setting live page mode flag as shown for mode: "${mode}". Reason: ${error.message}`;
		}
	};

	const handleModeChange = (newMode: LivePageMode) => {
		const modeDetails = {
			view: {
				title: intl.formatMessage(i18n.livePagesViewModeFlagTitle),
				description: intl.formatMessage(i18n.livePagesViewModeFlagDescription, {
					keyCombination: getShortcutString(LIVE_PAGE_MODE_SWITCH_TO_EDIT),
				}),
			},
			edit: {
				title: intl.formatMessage(i18n.livePagesEditModeFlagTitle),
				description: intl.formatMessage(i18n.livePagesEditModeFlagDescription, {
					keyCombination: getShortcutString(LIVE_PAGE_MODE_SWITCH_TO_VIEW),
				}),
			},
		};

		const selectedModeDetails = modeDetails[newMode];
		if (selectedModeDetails) {
			const { title, description } = selectedModeDetails;
			void setLivePageMode(newMode);

			if (!hasLivePageModeFlagBeenShown(newMode)) {
				const flagId = 'live-pages-mode-flag';
				// hide any flags that are currently showing
				void flags.hideFlag(flagId);
				void flags.showFlag({
					id: flagId,
					title,
					description,
					close: 'auto',
				});
				setLivePageModeFlagAsShown(newMode);
			}
		}
		createAnalyticsEvent?.({
			type: 'sendUIEvent',
			data: {
				source: analyticsSource,
				action: 'changed',
				actionSubject: 'mode',
				actionSubjectId: 'livePagesModeSwitcher',
				attributes: {
					previousMode: livePageMode,
					newMode,
				},
				objectType: 'page',
				objectId: contentId,
			},
		})?.fire();
	};

	const LivePagesModeChangerComponent = fg('cc_live_pages_toggle_move')
		? LivePagesModeChangerDropdown
		: LivePagesModeToggle;

	return (
		<LivePagesModeChangerComponent
			selectedMode={livePageMode ?? 'view'}
			onChange={handleModeChange}
			isDisabled={isDisabled}
			elemAfter={elemAfter}
		/>
	);
};
