import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl-next';

import { DropdownItem } from '@atlaskit/dropdown-menu';

import { useClassificationModalDispatchContext } from '../contexts/ClassificationModalContext';

const messages = defineMessages({
	classifyContent: {
		id: 'data-classification.ellipsis-classification-menu-item.classify-content',
		description:
			'Text in the dropdown menu item that user can click to open the content data classification popup',
		defaultMessage: 'Classify content',
	},
});

export const EllipsisClassificationMenuItem = ({
	elemBefore,
}: {
	elemBefore?: React.ReactNode;
}) => {
	const { setIsClassificationModalOpen } = useClassificationModalDispatchContext();

	const onClick = () => {
		setIsClassificationModalOpen(true);
	};

	return (
		<DropdownItem key="classify-content" onClick={onClick} elemBefore={elemBefore}>
			<FormattedMessage {...messages.classifyContent} />
		</DropdownItem>
	);
};
