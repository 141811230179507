import React from 'react';
import { defineMessages, useIntl, FormattedMessage } from 'react-intl-next';

import { Text } from '@atlaskit/primitives';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import BookOpenIcon from '@atlaskit/icon-lab/core/book-open';
import EditIcon from '@atlaskit/icon/core/edit';

import {
	LIVE_PAGE_MODE_SWITCH_TO_EDIT,
	LIVE_PAGE_MODE_SWITCH_TO_VIEW,
	getShortcutString,
} from '@confluence/shortcuts';

export type LivePageModes = 'edit' | 'view';
export interface LivePagesModeChangerDropdownProps {
	selectedMode: LivePageModes;
	onChange?: (value: LivePageModes) => void;
	isDisabled?: boolean;
	elemAfter?: ({ shortcutKeys, id }: { shortcutKeys: string[]; id: string }) => React.ReactNode;
}

const i18n = defineMessages({
	modeToggleToEdit: {
		id: 'live-pages-features.live-pages-mode-switcher.editing.edit-dropdown',
		defaultMessage: 'Resume editing',
		description:
			'Tooltip text that informs a user they can toggle to editing mode, and what key combination to press to toggle to editing.  In the tooltip, the key combination is present after this text.',
	},
	modeToggleToView: {
		id: 'live-pages-features.live-pages-mode-switcher.editing.view-dropdown',
		defaultMessage: 'Switch to viewing',
		description:
			'Dropdown text that informs a user they can toggle to viewing mode. In the dropdown item, the key combination is present after this text.',
	},
});

export const LivePagesModeChangerDropdown = ({
	selectedMode,
	onChange,
	isDisabled,
	elemAfter,
}: LivePagesModeChangerDropdownProps) => {
	const { formatMessage } = useIntl();

	const dropdownMenuContent =
		selectedMode === 'edit' ? i18n.modeToggleToView : i18n.modeToggleToEdit;
	const shortcut =
		selectedMode === 'edit' ? LIVE_PAGE_MODE_SWITCH_TO_VIEW : LIVE_PAGE_MODE_SWITCH_TO_EDIT;
	const keyCombination = getShortcutString(shortcut);

	const getShortcutComponent = () => {
		if (!keyCombination) {
			return null;
		}
		if (elemAfter) {
			return elemAfter({ shortcutKeys: keyCombination.split(' '), id: 'viewEditToggle' });
		}
		// for the old ..., menu we just have the plain shortcut string since the preview menu item is also doing this
		return keyCombination;
	};
	const Icon = selectedMode === 'edit' ? BookOpenIcon : EditIcon;
	return (
		<DropdownItem
			elemBefore={<Icon label="" />}
			elemAfter={getShortcutComponent()}
			testId="live-page-mode-toggle-label"
			onClick={() => onChange?.(selectedMode === 'view' ? 'edit' : 'view')}
			aria-label={formatMessage(dropdownMenuContent)}
			isDisabled={isDisabled}
		>
			<Text color={isDisabled ? 'color.text.disabled' : 'color.text'}>
				<FormattedMessage {...dropdownMenuContent} />
			</Text>
		</DropdownItem>
	);
};
