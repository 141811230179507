import React, { useLayoutEffect, useRef, type Ref } from 'react';
import { defineMessages, useIntl, FormattedMessage } from 'react-intl-next';

import Tooltip from '@atlaskit/tooltip';
import Toggle from '@atlaskit/toggle';
import { Inline, Pressable, Text, xcss } from '@atlaskit/primitives';

import {
	LIVE_PAGE_MODE_SWITCH_TO_EDIT,
	LIVE_PAGE_MODE_SWITCH_TO_VIEW,
	getShortcutString,
	ShortcutVisualizer,
} from '@confluence/shortcuts';

export type LivePageModes = 'edit' | 'view';
export interface LivePagesModeToggleProps {
	selectedMode: LivePageModes;
	onChange?: (value: LivePageModes) => void;
	isDisabled?: boolean;
}

const i18n = defineMessages({
	editingText: {
		id: 'live-pages-features.live-pages-mode-switcher.editing.label',
		defaultMessage: 'Editing',
		description:
			'Text that informs a user that they are in an editing state of the page and edits can be made to the content',
	},
	modeSwitcherLabel: {
		id: 'live-pages-features.live-pages-mode-switcher.label',
		defaultMessage: 'Page mode',
		description:
			'A screen reader label for the Live Pages Mode Switcher itself, to inform the user that "viewing" and "editing" switch the page mode.',
	},
	modeToggleToEditTooltip: {
		id: 'live-pages-features.live-pages-mode-switcher.editing.edit-tooltip',
		defaultMessage: 'Edit',
		description:
			'Tooltip text that informs a user they can toggle to editing mode, and what key combination to press to toggle to editing.  In the tooltip, the key combination is present after this text.',
	},
	modeToggleToViewTooltip: {
		id: 'live-pages-features.live-pages-mode-switcher.editing.view-tooltip',
		defaultMessage: 'Switch to viewing',
		description:
			'Tooltip text that informs a user they can toggle to viewing mode. In the tooltip, the key combination is present after this text.',
	},
});

const toggleWrapperXcss = xcss({
	display: 'flex',
	alignItems: 'center',
	flexDirection: 'row',
	userSelect: 'none',
	marginTop: 'space.025',
});

const pressableDisabledXcss = xcss({
	cursor: 'not-allowed',
});

export const LivePagesModeToggle = ({
	selectedMode,
	onChange,
	isDisabled,
}: LivePagesModeToggleProps) => {
	const { formatMessage } = useIntl();
	const toggleRef = useRef<HTMLInputElement>();
	const updateTooltipRef = useRef<() => void>();

	const tooltipMessage =
		selectedMode === 'edit' ? i18n.modeToggleToViewTooltip : i18n.modeToggleToEditTooltip;
	const keyCombination =
		selectedMode === 'edit'
			? getShortcutString(LIVE_PAGE_MODE_SWITCH_TO_VIEW)
			: getShortcutString(LIVE_PAGE_MODE_SWITCH_TO_EDIT);

	useLayoutEffect(() => {
		updateTooltipRef?.current?.();
	}, [tooltipMessage]);

	return (
		<Tooltip
			content={
				isDisabled
					? ''
					: ({ update }) => {
							// Ensures tooltip position updates when user changes modes and text gets updated
							// Per documentation: https://atlassian.design/components/tooltip/examples#updating-tooltip-position
							updateTooltipRef.current = update;
							return (
								<ShortcutVisualizer
									shortcut={keyCombination}
									contentBefore={formatMessage(tooltipMessage)}
									isEditorShortcut
								/>
							);
						}
			}
		>
			<Inline xcss={toggleWrapperXcss}>
				<Pressable
					testId="live-page-mode-toggle-label"
					onClick={() => {
						if (isDisabled) return;
						toggleRef?.current?.focus(); // Maintain a11y+design consistency by focusing on the toggle when the label is clicked
						onChange?.(selectedMode === 'edit' ? 'view' : 'edit');
					}}
					backgroundColor="color.background.neutral.subtle"
					aria-label={formatMessage(i18n.modeSwitcherLabel)}
					xcss={isDisabled ? pressableDisabledXcss : undefined}
				>
					<Text weight="medium" color={isDisabled ? 'color.text.disabled' : 'color.text'}>
						<FormattedMessage {...i18n.editingText} />
					</Text>
				</Pressable>
				<Toggle
					ref={toggleRef as Ref<HTMLInputElement>}
					onChange={(e) => {
						onChange?.(e?.target?.checked ? 'edit' : 'view');
					}}
					label={formatMessage(i18n.modeSwitcherLabel)}
					isChecked={selectedMode === 'edit'}
					isDisabled={isDisabled}
					testId="live-page-mode-toggle"
				/>
			</Inline>
		</Tooltip>
	);
};
